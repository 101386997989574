// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// import palletes from './_palletes.scss';
@use "palette.scss" as palette;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$footsnap-primary: mat.define-palette(palette.$md-green-palette);
$footsnap-accent: mat.define-palette(palette.$md-orange-palette);

// The warn palette is optional (defaults to red).
$footsnap-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$footsnap-theme: mat.define-light-theme(
  (
    color: (
      primary: $footsnap-primary,
      accent: $footsnap-accent,
      warn: $footsnap-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($footsnap-theme);

.primary-400 {
  background-color: mat.get-color-from-palette($footsnap-primary, 400);
}

button.mat-button-base {
  border-radius: 100px;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.content-center {
  justify-content: center;
}

.text-suttled {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #b9b9c1;
}

.line {
  height: 1px;
  width: 100%;
  background: #313131;
  opacity: 0.1;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 80px 0 0;
  font-family: Source Sans Pro, "Helvetica Neue", sans-serif;
  color: var(--light-black);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
}

//Variables

:root {
  //Colors
  --light-gray: #f5f5f5;
  --medium-gray: #ccc;

  --light-blue: #bbdbff;
  --medium-blue: #489fff;
  --dark-blue: #1167c7;

  --white: #fff;
  --black: #000;
  --light-black: #313131;

  --green-dark: #085608;
  --green-medium: #2a9d8f;
  --green-light: #dff6f4;

  --medium-red: #ff0000;
  --light-red: #e72429;

  --medium-orange: #f4a261;

  --primary-color: #f4a261;
  --light-gray-color: #edf1f7;
  --gray-color: #8f9bb3;
  --black-color: #010112;
  --green-tint-color: #dff6f3;
  --green-color: #2a9d8f;
  --suttled-color: #b9b9c1;
  --disabled-button-color: #bababa;

  --header-height: 80px;
}

.primary-color {
  background-color: var(--primary-color);
}
.light-gray-color {
  background-color: var(--light-gray-color);
}
.gray-color {
  background-color: var(--gray-color);
}
.black-color {
  background-color: var(--black-color);
}
.green-tint-color {
  background-color: var(--green-tint-color);
}
.green-color {
  background-color: var(--green-color);
}

.separator-line {
  height: 1px;
  width: 100%;
  background: var(--light-gray-color);
  margin-block: 10px 13px;
  &-xs {
    margin-block: 4px;
  }
}

.h-100 {
  height: 100%;
}

.top-info {
  background-color: var(--green-medium);
  background-image: url("/assets/images/top-info-background.png");
  background-blend-mode: multiply;
  background-size: cover;
  border-radius: 0 0 20px 20px;
  padding: 30px 15px 90px;
  color: var(--white);

  &__list-wrapper {
    margin: -60px 15px 45px;
    z-index: 15;
    background-color: var(--white);
    border-radius: 20px;
    padding: 16px;
    color: var(--light-black);

    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  }
}

circle-progress {
  svg {
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.4));
  }
}

.top-title {
  background: var(--light-gray);
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
}

.orange-button {
  font-family: Poppins, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  padding: 11px 24px;
  background: var(--medium-orange);
  border-radius: 100px;
  width: 100%;
  border: none;
  color: var(--white);

  &:hover,
  &:focus {
    box-shadow: 0 7px 20px rgb(0, 0, 0, 0.25);
  }
}

.green-button {
  @extend .orange-button;
  background-color: var(--green-light);
  color: var(--green-dark);
}

.player-wrapper {
  vg-player video {
    max-height: 200px;
  }

  &__screensaver {
    img {
      width: 100%;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: Poppins, "Helvetica Neue", sans-serif;
}

button {
  font-family: Source Sans Pro, "Helvetica Neue", sans-serif;
  &:disabled {
    background-color: var(--disabled-button-color);
    opacity: 0.8;
  }
}

.w-100 {
  width: 100% !important;
}

.color-green {
  color: var(--green-medium);
}

.color-red {
  color: var(--light-red);
}

.text-center {
  text-align: center;
}

.m-footer {
  margin-bottom: 56px !important;
}

//Common classes

.centered-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-fixed-cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.instructions-button {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  display: flex;
  justify-content: end;
  bottom: 100px;
  right: 20px;
}

.recording-wrapper {
  height: 60vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--dark-blue);
  margin: 20px 0;
  border-radius: 5px;
  box-sizing: border-box;
}

//Changed material styles

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--green-medium);
}

.mat-progress-bar-fill::after {
  background-color: var(--dark-blue);
}

.mat-progress-bar-buffer {
  background: var(--light-blue);
}

/*#uploadVideo {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}*/
//only for first sprint and safari
@media screen and (orientation: landscape) {
  .ios-button {
    padding: 10px;
    width: 100px !important;
    bottom: calc(50% - 35px) !important;
    box-sizing: border-box;
  }
}

// prime styles
.p-inputtext {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.18);
  border-radius: 30px;
  padding-inline: 24px;
  padding-block: 11px;
}

.p-button {
  &-sm {
    padding: 8px !important;
  }
  &-xs {
    padding: 4px !important;
  }
  background: var(--primary-color) !important;
  border-radius: 100px;
  padding-block: 11px;
  border: none;
  &:hover,
  &focus,
  &active {
    background: var(--primary-color) !important;
  }

  &-tint {
    background: var(--green-tint-color) !important;
    color: var(--green-color) !important;
    &:hover,
    &focus,
    &active {
      background: var(--green-tint-color) !important;
    }
  }
  &.p-button-secondary-text {
    color: var(--green-color) !important;
  }
  &.p-button-secondary {
    background: var(--green-color) !important;
    color: var(--green-tint-color) !important;
    border: none;
  }

  &-text {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    &:hover,
    &focus,
    &active {
      background-color: transparent !important;
    }
  }
}

.p-checkbox {
  .p-checkbox-box {
    border: 2px solid #25897d;
    border-radius: 5px;

    &.p-highlight {
      &,
      &:focus,
      &:hover {
        background-color: #25897d !important;
        border: 2px solid #25897d !important;
        // box shadow to make inner border between real border and background
        box-shadow: inset 0 0 0 2px #dff6f3;
      }
    }

    .p-checkbox-icon {
      &.pi-check:before {
        content: "" !important;
      }
    }
  }
}

// desktop styles
@media (min-width: 900px) {
  .p-button {
    max-width: 50% !important;
  }
  form {
    max-width: 600px;
    margin: 0 auto;
  }
}
